<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'shop',
	metaInfo: {
		title: 'Checkout',
	},
	cssVars: {},
	data() {
		return {
			checkout: {
				style: this.$srv('CheckoutStyle.name'),
				order: null,
				hash: null,
				stepsData: null,
				checker: null,
				checkerTest: null,
				firstLoading: true,
				calculatedUntil: null,
				loadingStep: false,
				loadingSubmit: false,
				disallowCheckerMessages: false,
				validation: {},
				stepViewData: {},
				beforeConfirm: (...args) => this.beforeConfirm(...args),
				stepSubmit: (...args) => this.stepSubmit(...args),
				reqWrapper: (...args) => this.reqWrapper(...args),
				gotoStep: (...args) => this.gotoStep(...args),
				modifyStep: (...args) => this.modifyStep(...args),
				scrollToStep: (...args) => this.scrollToStep(...args),
				initQuery: null,
			},
			hiddenCollapsedSummary: false,
			scrollId: null,
			simpleOrder: this.$srv('simpleOrder'),
		}
	},
	inject: ['getAppMessageDialog'],
	provide() {
		return {
			xcheckout: () => this.checkout,
		}
	},
	computed: {
		hash: get('route@params.hash'),
		stepKey() {
			return this.$route.params.stepKey || 'init'
		},
		stepExists() {
			return this.stepKey == 'init' || this.steps.some((x) => x.key == this.stepKey)
		},
		steps() {
			const availableSteps = Object.keys(this.checkout.stepsData || {})
			return [
				{ key: 'signin', title: this.$lang('Tus datos'), editable: false },
				{ key: 'discount', title: this.$lang('Aplicá descuentos') },
				{ key: 'delivery', title: this.$lang('Elige cómo recibir tu compra') },
				{ key: 'payment', title: this.$lang('Elige la forma de pago') },
				{ key: 'contact', title: this.$lang('Contacto y Facturación') },
				{
					key: 'confirm',
					title:
						this.simpleOrder || this.checkout.order?.payments?.[0]?.methodKey == 'Void'
							? this.$lang('Confirma el pedido')
							: this.$lang('Realizá el pago'),
				},
			].filter((x) => availableSteps.includes(x.key))
		},
	},
	watch: {
		stepKey(value) {
			if (value && this.stepExists) {
				this.loadStep()
			}
		},
	},
	methods: {
		async reqWrapper(apiPostFn) {
			const data = { hash: this.hash, checker: this.checkout.checker }
			const done = (response) => {
				if (response.data.checkout) {
					this.$assignDeep(this.checkout, response.data.checkout)
				}

				if (response.success) {
					if (this.checkout.checkerTest?.itemsChanged && !this.checkout.disallowCheckerMessages) {
						this.showItemsChangedMessage()
					}
				} else {
					response.isCheckoutError = this.catchApiErrors(response)
				}

				this.checkout.disallowCheckerMessages = false
			}

			let response = await apiPostFn({ data, done })
			return response
		},
		async initCheckout() {
			this.checkout.disallowCheckerMessages = true
			this.checkout.initQuery = { ...(this.$route.query || {}) }
			await this.$shopApi.get({
				url: '/checkout/init',
				loading: (v) => (this.loadingSubmit = v),
				query: this.$route.query,
				onSuccess: ({ data: { checkout, jumpTo } }) => {
					this.$assignDeep(this.checkout, checkout)
					this.$eventer().trigger('checkout:init', {
						order: checkout.order,
					})
					this.$trackers.pageView()
					this.$router.replace(this.getStepRoute(jumpTo, { hash: checkout.hash }))
				},
				onError: (response) => this.catchApiErrors(response),
			})
		},
		async loadStep() {
			this.checkout.loadingStep = this.stepKey
			const { firstLoading } = this.checkout

			let scrollPromise = null
			if (this.checkout.style == 'multi') {
				this.$vuetify.goTo(0, { duration: 0 })
			} else if (!firstLoading && this.checkout.style == 'single') {
				let delay = this.$route.params.fromSubmit ? 2000 : 0
				let duration = this.$route.params.fromSubmit ? 500 : 0
				this.$nextTick(() => {
					scrollPromise = new Promise((resolve) => {
						setTimeout(resolve, delay)
					})
					// this.scrollToStep(this.stepKey, { duration, delay })
				})
			}

			const response = await this.reqWrapper(({ data, done }) => {
				return this.$shopApi.post({
					url: `/checkout/load-step/${this.stepKey}`,
					data,
					loading: false,
					done,
				})
			})

			if (!response.success) return

			let { jumpTo, stepViewData } = response.data
			if (jumpTo) {
				this.gotoStep(jumpTo)
				return
			}

			this.checkout.stepViewData = this.$assignDeep({}, stepViewData || {})

			this.$eventer().trigger('checkout:step-loaded', {
				stepKey: this.stepKey,
				order: this.checkout.order,
			})

			this.$nextTick(() => {
				this.checkout.loadingStep = null
				this.trackPageView()
				if (this.checkout.style == 'single' || this.stepKey == 'confirm') {
					this.$nextTick(() => {
						this.$nextTick(async () => {
							if (scrollPromise) await scrollPromise
							// this.scrollToStep(this.stepKey, { duration: 500, delay: firstLoading ? 250 : 0 })
						})
					})
				}
			})
		},
		async stepSubmit(payload) {
			const isConfirm = this.stepKey == 'confirm'
			this.checkout.loadingSubmit = !isConfirm

			const response = await this.reqWrapper(({ data, done }) => {
				data.payload = payload || {}
				return this.$shopApi.post({
					url: `/checkout/submit-step/${this.stepKey}`,
					data,
					loading: false,
					onValidation: ({ validation }) => (this.checkout.validation = validation),
					done,
				})
			})

			if (response.success) {
				await this.$eventer().trigger('checkout:step-submit', {
					stepKey: this.stepKey,
					order: this.checkout.order,
				})

				let { nextStepKey } = response.data

				if (nextStepKey) {
					if (this.checkout.style == 'single') {
						// this.scrollToStep(this.stepKey)
					}
					this.$nextTick(() => {
						this.gotoStep(nextStepKey, { fromSubmit: true })
					})
				} else if (isConfirm) {
					this.$store.set('cart/order', null)
				}
			}

			this.$nextTick(() => {
				this.checkout.loadingSubmit = false
			})

			return response
		},
		scrollToStep(stepKey, { delay = 0, duration = 0 } = {}) {
			let lastScrollId = this.scrollId
			this.scrollId = setTimeout(() => {
				if (lastScrollId) clearTimeout(lastScrollId)
				let idx = this.steps.findIndex((x) => x.key == stepKey)
				if (idx == -1) return
				this.$vuetify.goTo(this.$refs.steps[idx], { duration, offset: 30 })
			}, delay)
		},
		catchApiErrors({ data, message, options, validation }) {
			if (data.checkerFailed) {
				if (!this.checkout.disallowCheckerMessages) {
					this.showCheckerFailedMessage()
				}
				this.loadStep(this.stepKey)
			} else if (data.invalidStepKey) {
				if (data.invalidStepKey == this.stepKey) {
					if (!validation) {
						this.loadStep(this.stepKey)
					}
				} else {
					options.abort()
					this.gotoStep(data.invalidStepKey)
				}
			} else if (message?.code == 'invalid_checkout_hash') {
				options.abort()
				if (data.orderId) {
					this.$router.push({
						name: 'user.order-detail',
						params: { id: data.orderId },
					})
				} else {
					this.$router.push({ name: 'home' })
				}
			} else if (message?.code == 'cart_empty') {
				options.abort()
				this.$store.set('cart/order', null)
				this.$router.push({ name: 'home' })
			} else if (message?.code == 'cart_items_removed') {
				options.abort()
				this.$store.set('cart/order', null)
				this.$router.push({ name: 'home' })
			} else {
				return false
			}

			return true
		},
		getStepRoute(stepKey, customParams) {
			return {
				name: 'checkout.step',
				params: {
					hash: this.hash,
					scrollPosition: 'keep',
					...(customParams || {}),
					stepKey,
				},
			}
		},
		gotoStep(stepKey, customParams) {
			let route = this.getStepRoute(stepKey, customParams)
			return this.$router.push(route).catch((err) => null)
		},
		modifyStep(stepKey) {
			return this.gotoStep(stepKey, { modifying: true })
		},
		trackPageView() {
			let location = this.$router.resolve({
				name: this.$route.name,
				params: {
					...this.$route.params,
					hash: '__HASH__',
				},
				query: this.$route.query,
			}).href
			this.$meta().refresh()
			this.$trackers.pageView({ location })
		},
		onCheckoutSummaryIntersect(entries) {
			this.hiddenCollapsedSummary = !entries[0].isIntersecting
		},
		showCheckerFailedMessage() {
			this.getAppMessageDialog().open({
				type: 'warning',
				title: 'El monto total o alguno de tus productos ha cambiado',
				text: [
					`Para asegurarte de que todo esté bien, revisa el resumen de tu compra.\n`,
					`Revisa el monto total a pagar, puede que hayan cambiado los costos.`,
					`También, es posible que tú hayas modificado el carrito o que alguno de los productos ya no estén publicados o no cuenten con el stock suficiente.\n`,
					`Si todo está en orden y estás de acuerdo con tu compra, puedes continuar sin problemas.`,
				].join(`\n`),
			})
		},
		showItemsChangedMessage() {
			this.getAppMessageDialog().open({
				type: 'warning',
				title: 'Tus productos han cambiado',
				text: [
					`Para asegurarte de que todo esté bien, revisa el resumen de tu compra.\n`,
					`Es posible que tú hayas modificado el carrito o que alguno de los productos ya no estén publicados o no cuenten con el stock suficiente.`,
				].join(`\n`),
			})
		},
		async beforeConfirm() {
			return await this.$eventer().trigger(
				'checkout:before-confirm',
				{ checkout: this.checkout },
				{ stopable: true }
			)
		},
	},
	async mounted() {
		if (this.stepKey == 'init') await this.initCheckout()
		else await this.loadStep()
		this.checkout.firstLoading = false
	},
}
</script>

<template>
	<div class="pb-16">
		<CheckoutCollapsedSummary v-if="$b.mt" />
		<v-container :fluid="$b.m" :style="$b.td ? `width: 90%; max-width: 1380px;` : ''">
			<v-row>
				<!-- steps -->
				<v-col cols="12" md="7" class="pt-5 px-0 px-md-4 pb-10 pb-md-0">
					<div v-super-sticky="$b.d ? { top: 20, bottom: 20 } : false">
						<CheckoutStepBox
							v-for="step of steps"
							:key="step.key"
							class="mb-6 mb-md-4"
							:step="step"
							:all-steps="steps"
							ref="steps"
						/>
						<!-- <div
							v-if="checkout.style == 'single'"
							:style="{ height: $b.td ? '75vh' : '50vh' }"
							:class="{ 'd-none': stepKey == 'confirm' && !checkout.loadingStep }"
						/> -->
					</div>
				</v-col>
				<!-- summary -->
				<v-col cols="12" md="5" class="pt-5 pb-0" v-if="$b.d">
					<CheckoutSummary v-super-sticky="{ top: 20, bottom: 20 }" />
				</v-col>
			</v-row>
		</v-container>
		<v-overlay :value="checkout.loadingSubmit || checkout.firstLoading" z-index="999999">
			<v-progress-circular indeterminate size="80" />
		</v-overlay>
	</div>
</template>

